// Build information, automatically generated by `ng-info`
const build = {
    version: "1.7.1",
    timestamp: "Thu Aug 29 2024 17:10:16 GMT+0100 (GMT+01:00)",
    message: null,
    git: {
        user: "LV-fbonhoure",
        branch: "stable",
        hash: "658d64",
        fullHash: "658d6420d10c529387613984677c7d51bf474b78"
    }
};

export default build;