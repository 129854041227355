import {Component, OnInit} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {of, EMPTY, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {BackendService} from 'src/app/services/backend/backend.service';
import {FormUtilsService} from 'src/app/services/form-utils/form-utils.service';
import {DialogComponent} from 'src/app/utils/dialog/dialog.component';
import {FormFieldComponent} from 'src/app/utils/form/form-field/form-field.component';
import {Action} from 'src/app/utils/model/action.model';
import {Nepting} from 'src/app/utils/model/nepting/nepting.model';
import {Offer} from 'src/app/utils/model/offer.model';
import {Order} from 'src/app/utils/model/order.model';
import {PhoneValidator} from 'src/app/utils/validators/phone.validator';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  constructor(
    private formUtilsService: FormUtilsService,
    private backendService: BackendService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private phoneValidator: PhoneValidator,
  ) {
  }

  id = this.route.snapshot.params.id;
  order = Order.reduce(new Order(this.route.snapshot.data.resolve.order));
  nepting = this.route.snapshot.data.resolve.nepting.map(nepting => new Nepting(nepting));
  actions = [...this.getDefaultActions(this.order), ...this.route.snapshot.data.resolve.actions.map(action => new Action(action))];
  refOffer = this.route.snapshot.data.resolve.refOffers.map(refOffer => new Offer(refOffer))
    .find(refOffer => refOffer.id === this.order.suborders[0].id_offer);
  form = this.formUtilsService.buildForm(this.order) as FormGroup;

  actionsConfig: any[] = [
    {
      label: 'Commande enregistrée',
      name: 'order',
    },
    {
      label: 'Documents contractuels signés',
      name: 'sign',
      actions: {
        ...(!this.refOffer || this.refOffer?.manual_sign) ?
          {'J\'ai signé mon contrat papier et j\'envoie ma commande': this.manualSign.bind(this)} : null,
        ...((!this.refOffer || this.refOffer.certeurope) && this.order.suborders.every(
          suborder => suborder.contracts.every(
            contract => !contract.signed
          )
        ) ? {'Envoyer email pour signature electronique': this.certeuropeSign.bind(this)} : null)
      },
      passedActions: {
        ...((!this.refOffer || this.refOffer.certeurope) && this.order.suborders.every(
          suborder => suborder.contracts.every(
            contract => contract.signed_url
          )
        ) ? {'Renvoyer email avec documents signés': this.signedMail.bind(this)} : null)
      }
    },
    {
      label: 'Commande transmise',
      name: 'nepting',
      roles: ['advancedSchema'],
      actions: {
        ...(this.order.suborders.some(suborder => suborder.equipments.some(
          equipment => equipment.nepting && equipment.softwares.some(
            software => software.nepting_application_type
          ))) ? {'Renvoyer les actions nepting': _ => this.neptingOrder()} : null),
        'Renvoyer la commande au mainteneur': this.publishOrder.bind(this)
      },
    },
    {
      label: 'Commande en cours de traitement',
      name: 'handling'
    },
    // (
    //   this.order.suborders[0].installation_mode=="onsite"?
    //   ({
    //     label: 'Commande installée',
    //     name: 'installation_done'
    //   }):
    //   ({
    //     label: 'Commande expédiée',
    //     name: 'delivery'
    //   })
    // ),
    {
      label: 'Commande terminée',
      name: 'order_end'
    }
  ];

  neptingConfig: {formControlName: string, label: string, input: string}[] = [
    {
      formControlName: 'publish',
      label: 'Envoyer la commande au mainteneur après les actions',
      input: 'slide-toggle'
    },
    {
      formControlName: 'forceActions',
      label: 'Forcer l\'envoi de toutes les actions',
      input: 'slide-toggle'
    },
    {
      formControlName: 'wait',
      label: 'Attendre la fin de tous les appels (déconseillé)',
      input: 'slide-toggle'
    },
  ];

  certeuropeConfig = [
    {
      formControlName: 'email',
      label: 'Adresse mail',
      type: 'email',
      pattern: /\S*/,
      maxLength: 80
    },
    {
      formControlName: 'mobile',
      label: 'Téléphone mobile',
      type: 'tel',
      pattern: /[0-9]{10}/,
      minLength: 10,
      maxLength: 10,
      validators: [this.phoneValidator],
      error: (error: { [key: string]: string }): string => {
        return Object.keys(error)?.[0];
      },
    },
  ];

  getTitle(): string {
    return Object.entries({
      'Commande n°': ['suborders.0.bnppsm_order_number'],
      Agence: ['suborders.0.branch_code'],
      Conseiller: ['user.firstname', 'user.lastname']
    }).map(([k, v]) => `${k} ${v.map(_ => this.form.get(_).value).join(' ')}`).join(' / ');
  }

  ngOnInit(): void {
    this.form.disable();
  }

  neptingToAction(order: Order, neptings: Nepting[]): Action[] {
    const neptingIds = ['applicationId', 'storeId', 'serialNumber', 'terminalSerialNumber', 'userId', 'oldUserId', 'newUserId', 'merchantUserId', 'merchantId'];

    return neptings.reduce((acc, val) => {
      const index = acc.findIndex(_ => {
        return neptingIds.every(id => _?.request[id] === val?.request[id]) &&
          Nepting.getActionLabelSuffix(_?.action) === Nepting.getActionLabelSuffix(val?.action);
      });
      if (index >= 0) {
        acc[index] = val;
      } else {
        acc.push(val);
      }
      return acc;
    }, []).map(nepting => {
      const error = !['NO_SYS_CHANGES_DONE'].includes(nepting.error) && nepting.error;
      const detail = [
        nepting.response?.detail,
        ...(nepting.response?.application?.map(app => app.detail) ?? []),
        ...Object.entries(nepting.response ?? {}).filter(([k, v]) => k.toLowerCase().endsWith('active') && !v).map(_ => 'Inactif')
      ].filter(_ => _).join(', ');
      const hostMerchantName = nepting.response?.hostMerchantName ? `\n(ligne plaque "${nepting.response?.hostMerchantName}")` : '';
      return new Action({
        id_order: order.id,
        id_offer: order.suborders[0].id_offer,
        name: 'nepting',
        date: nepting.created_date,
        sent: nepting.updated_date,
        id_maintainer: order.suborders[0].id_maintainer,
        maintainer_information: {
          comment: `${Nepting.getActionLabel(nepting.action)} ${neptingIds.reduce((acc, val) => acc || nepting.request[val], null)} : ${error ? `KO (${error})` : (detail ? `INCONNU (${detail})` : `OK${hostMerchantName}`)}`
        }
      });
    });
  }

  getDefaultActions(order: Order): Action[] {
    const actions: Action[] = [
      new Action({
        id_order: order.id,
        id_offer: order.suborders[0].id_offer,
        name: 'order',
        date: order.created_date,
        sent: order.updated_date,
        id_maintainer: order.suborders[0].id_maintainer,
        maintainer_information: {}
      }),
      ...this.neptingToAction(order, this.nepting)
    ];

    // if (order.suborders.length && order.suborders.every(
    //   suborder => suborder.contracts.length && suborder.contracts.every(contract => contract.signed)
    // )){
    //   actions.push(new Action({
    //     id_order: order.id,
    //     id_offer: order.suborders[0].id_offer,
    //     name: 'sign',
    //     date: order.suborders[0].contracts[0].created_date,
    //     sent: order.suborders[0].contracts[0].updated_date,
    //     id_maintainer: order.suborders[0].id_maintainer,
    //     maintainer_information: {
    //       comment: order.suborders.reduce((acc, val) => acc.concat(val.contracts),[])
    //         .map(_ => 'Signature du ' + _.label?.toLowerCase()).join(', ')
    //     }
    //  }));
    // }
    return actions;
  }

  neptingOrder(params = {}): Observable<any> {
    const form = this.formUtilsService.buildForm({
      publish: false,
      forceActions: false,
      wait: false,
      ...params
    }) as FormGroup;
    let observable: Observable<any>;
    if (Object.values(params).length) {
      observable = of(true);
    } else {
      observable = this.dialog.open(DialogComponent, {
        data: {
          title: 'Renvoyer les actions nepting avec les options suivantes : ',
          buttons: {
            Envoyer: true,
          },
          components: this.neptingConfig.map(c => ({
            name: FormFieldComponent,
            inputs: {
              form,
              config: c
            }
          }))
        }
      }).beforeClosed();
    }
    return observable.pipe(
      switchMap(_ => {
        if (_) {
          return this.backendService.request('get', `nepting/order/${this.form.get('id').value}`, {params: form.getRawValue()});
        }
        return EMPTY;
      })
    );
  }

  publishOrder(): Observable<any> {
    return this.backendService.request('get', `publish/order/${this.form.get('id').value}`);
  }

  certeuropeSign(): Observable<any> {
    const form = this.formUtilsService.buildForm({
      email: this.order.customer.delivery_address.email,
      mobile: this.order.customer.delivery_address.phone
    }) as FormGroup;
    return this.dialog.open(DialogComponent, {
      data: {
        title: 'Envoyer email pour signature electronique',
        buttons: {
          Envoyer: true,
        },
        components: this.certeuropeConfig.map(c => ({
          name: FormFieldComponent,
          inputs: {
            form,
            config: c
          }
        }))
      }
    }).beforeClosed().pipe(
      switchMap(_ => {
        if (_) {
          const value = form.getRawValue();
          const params = {};
          if (value.email) {
            params['holder.email'] = value.email;
          }
          if (value.mobile) {
            params['holder.mobile'] = value.mobile;
            params['otpContact'] = value.mobile;
          }
          return this.backendService.request('get', `inactive/certeurope/create/${this.form.get('id').value}`, {params});
        }
        return EMPTY;
      })
    );
  }

  manualSign(): Observable<any> {
    if ((this.form.get('suborders') as FormArray).getRawValue().every(
      suborder => suborder.contracts.length && suborder.contracts.every(contract => contract.downloaded))
    ) {
      return this.backendService.request('post', `contracts/sign/${this.form.get('id').value}`)
        .pipe(
          switchMap(_ => {
            const signAction = this.actions.find(action => action.name === 'sign');
            if (signAction) {
              return of(signAction);
            }
            return this.backendService.request(
              'get', `actions/sign/${this.form.get('id').value}`).pipe(tap(action => this.actions.push(action)
            ));
          }),
          switchMap(_ => {
            const neptingActions = this.actions.filter(action => action.name === 'nepting');
            if (neptingActions?.length) {
              return of(neptingActions);
            }
            return this.neptingOrder({publish: true}).pipe(
              map(nepting => this.neptingToAction(this.form.getRawValue(), nepting))
              , tap(actions => this.actions.push(...actions))
            );
          })
        );
    } else {
      this.dialog.open(DialogComponent, {
        disableClose: true,
        data: {
          content: `ATTENTION : Avant de valider votre commande, le contrat de location ou de vente doit être impérativement paraphé et signé. Vous devez en conserver une copie.<br>
          Après la validation de votre commande, vous ne pourrez plus la modifier.`,
          buttons: {
            OK: true
          }
        }
      });
      return EMPTY;
    }
  }

  signedMail(): Observable<any> {
    const form = this.formUtilsService.buildForm({
      email: this.order.customer.delivery_address.email
    }) as FormGroup;
    return this.dialog.open(DialogComponent, {
      data: {
        title: 'Renvoyer email avec documents signés',
        buttons: {
          Envoyer: true,
        },
        components: [this.certeuropeConfig[0]].map(c => ({
          name: FormFieldComponent,
          inputs: {
            form,
            config: c
          }
        }))
      }
    }).beforeClosed().pipe(
      switchMap(_ => {
        if (_) {
          const value = form.getRawValue();
          const params = {};
          if (value.email) {
            params['holder.email'] = value.email;
          }
          return this.backendService.request('get', `inactive/certeurope/mail/${this.form.get('id').value}`, {params});
        }
        return EMPTY;
      })
    );
  }
}
